import React from "react";
import { Grid, Hidden, Button } from "@material-ui/core";
import arrowRight from "./img/arrow-right.svg";
import { makeStyles } from "@material-ui/core/styles";
import ContainerForLinks from "../containerForLinks";
import CategoryBlock from "../landingPageComponents/blocks/categoryBlock";
import { buildImageObj, publishDate, createClient } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";

const useStyles = makeStyles((theme) => ({
  nameText: {
    marginTop: ".8em",
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    color: "#2F2F4E",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    maxHeight: '3.6em'
  },
  catSpan: {
    display: "inline",
    flexdirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px",

    background: "#FFFFFF",
    border: "0.5px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#50586C",
    marginRight: '1em'
  },
  profile: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: "24px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    width: "100%",
    padding: "1.3em",
    border: '1px solid #DBE3EF',
    height: '14.5em',
    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
    },
  },
  articleImage: {
    borderRadius: '8px',
    maxHeight: '100%'
  },
  maxFullHeight: {
    maxHeight: '100%',
    position: 'relative'
  },
  textHolder: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    bottom: '3.5em',
    position: 'absolute',
    display: 'contents'
  },
  authorName: {
    color: '#3C455C',
    fontStyle: 'italic',
    paddingRight: '1em'
  },
  publishedDate: {
    color: '#3C455C',
    fontWeight: 'normal',
    opacity: '0.75',
  },
  readMoreContainer: {
    position: 'absolute',
    bottom: '.4em',
    color: '#3C455C',
    fontWeight: '600',
    letterSpacing: '0.04em'
  },
  arrowRight: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    transform: 'translateY(-50%)'
  },
  readMore: {
    marginLeft: '1.8em'
  },
  mobileContainer: {
    background: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #DBE3EF',
    height: '10em',
    marginTop: '1em',
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    fontFamily: 'Poppins',
    fontStyle: 'normal'
  },
  mobileImageContainer: {

  },
  mobileContentContainer: {
    position: 'relative',
    width: '60%',
    float: 'left',
    height: '100%'
  },
  mobileImage: {
    maxWidth: '40%',
    float: 'right',
    borderRadius: '0px 8px 8px 0px',
    maxHeight: '100%'
  },
  mobileTitle: {
    position: 'absolute',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#262641',
    top: '1.5em',
    left: '1em'
  },
  mobileDate: {
    position: 'absolute',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    opacity: '0.75',
    color: '#50586C',
    bottom: '1.5em',
    left: '1em'
  }
}));

function ArticleCard(props) {

  const { article } = props;
  const pageType = "post";
  const classes = useStyles();
  var imgURL = "";

  if (typeof article.mainImage != "undefined") {
    imgURL = imageUrlFor(buildImageObj(article.mainImage));
  }
  

  return (
    <ContainerForLinks
      reference={article}
      _type={pageType}
    >
      <Hidden only={['xs']} implementation="js">
        <Grid container className={classes.profile} >
          <Grid item xs={8} className={classes.maxFullHeight}>
            <Grid container>
              <CategoryBlock catTitle={article.catTitle} />
              {/*{article.catTitle && article.catTitle.map(cats =>*/}
              {/*  <Grid item className={classes.catSpan}>*/}
                  
              {/*  </Grid>*/}
              {/*)}*/}
            </Grid>
            <div className={classes.nameText}>
              <span>
                {article.title}
              </span>
            </div>

            <div className={classes.textHolder}>
              <span className={classes.authorName}>
                
                {article.people2 && article.people2.map(au =>
                  <>By {au.name}</>
                )}

                {!article.people2 && <>By Boring Money</>}
              </span>
              <span className={classes.publishedDate}> {publishDate(article.publishedAt)}</span>
            </div>
            <Grid container className={classes.readMoreContainer}>
              <Grid item>
                <img className={classes.arrowRight} src={arrowRight} />
              </Grid>
              <Grid item>
                <span className={classes.readMore}>READ MORE</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={4} item className={classes.maxFullHeight} container justify='flex-end' justify='flex-end'>
            {article.mainImage && <img
              src={imgURL
                .width(220)
                .height(220)
                .fit("crop")
                .auto("format")
                .url()}
              className={classes.articleImage}
            />}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="js">
        <div className={classes.mobileContainer}>
          <div className={classes.mobileContentContainer}>
            <span className={classes.mobileTitle}>
              {article.title}
            </span>
            <span className={classes.mobileDate}>{publishDate(article._updatedAt)}</span>
          </div>
          {article.mainImage && <img
            src={imgURL
              .width(220)
              .height(220)
              .fit("crop")
              .auto("format")
              .url()}
            className={classes.mobileImage}
          />}
        </div>
      </Hidden>
    </ContainerForLinks>
  );
}


export default ArticleCard;


function ShowImg(props) {
  const { mainImage } = props;
  var imgObj = buildImageObj(mainImage);
  const classes = useStyles();
  return (<img
    src={imageUrlFor(imgObj.asset)
      .width(220)
      .height(220)
      .fit("crop")
      .auto("format")
      .url()}
    className={classes.artImage}
  />)
}
