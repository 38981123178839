import React from "react";
import { Grid, Hidden, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton"

const useStyles = makeStyles((theme) => ({
  nameText: {
    marginTop: ".8em",
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    color: "#2F2F4E",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    maxHeight: '3.6em'
  },
  catSpan: {
    display: "inline",
    flexdirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px",

    background: "#FFFFFF",
    border: "0.5px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#50586C",
    marginRight: '1em'
  },
  profile: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: "24px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    width: "100%",
    padding: "1.3em",
    border: '1px solid #DBE3EF',
    height: '14.5em',
    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
    },
  },
  articleImage: {
    borderRadius: '8px',
    maxHeight: '100%'
  },
  maxFullHeight: {
    maxHeight: '100%',
    position: 'relative'
  },
  textHolder: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    bottom: '3.5em',
    position: 'absolute',
    display: 'contents'
  },
  authorName: {
    color: '#3C455C',
    fontStyle: 'italic',
    paddingRight: '1em'
  },
  publishedDate: {
    color: '#3C455C',
    fontWeight: 'normal',
    opacity: '0.75',
  },
  readMoreContainer: {
    position: 'absolute',
    bottom: '.4em',
    color: '#3C455C',
    fontWeight: '600',
    letterSpacing: '0.04em'
  },
  arrowRight: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    transform: 'translateY(-50%)'
  },
  readMore: {
    marginLeft: '1.8em'
  },
  mobileContainer: {
    background: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #DBE3EF',
    height: '10em',
    marginTop: '1em',
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    fontFamily: 'Poppins',
    fontStyle: 'normal'
  },
  mobileImageContainer: {

  },
  mobileContentContainer: {
    position: 'relative',
    width: '60%',
    float: 'left',
    height: '100%'
  },
  mobileImage: {
    maxWidth: '40%',
    float: 'right',
    borderRadius: '0px 8px 8px 0px',
    maxHeight: '100%'
  },
  mobileTitle: {
    position: 'absolute',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#262641',
    top: '1.5em',
    left: '1em'
  },
  mobileDate: {
    position: 'absolute',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    opacity: '0.75',
    color: '#50586C',
    bottom: '1.5em',
    left: '1em'
  }
}));

function ArticleCardSkeleton(props) {

  const pageType = "post";
  const classes = useStyles();

  return (
    <>
      <Hidden only={['xs']} implementation="js">
        <Grid container className={classes.profile} >
          <Grid item xs={8} className={classes.maxFullHeight}>
            <Grid container>
                <Grid item className={classes.catSpan}>
                  <Skeleton variant="rect" width={60}/>
                </Grid>
            </Grid>
            <div className={classes.nameText}>
              <span>
                <Skeleton variant={"rect"} width={500} />
              </span>
            </div>

            <div className={classes.textHolder}>
              <span className={classes.authorName}>

                <Skeleton variant={"rect"} width={248} />
              </span>
              <span className={classes.publishedDate}>
              </span>
            </div>
            <Grid container className={classes.readMoreContainer}>
              <Grid item>
              </Grid>
              <Grid item>
                <span className={classes.readMore}>
                  <Skeleton variant={"rect"} width={125} />
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={4} item className={classes.maxFullHeight} container justify='flex-end' justify='flex-end'>
            <Skeleton variant={"rect"} height={188} width={188}/>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="js">
        <div className={classes.mobileContainer}>
          <div className={classes.mobileContentContainer}>
            <span className={classes.mobileTitle}>
              <Skeleton variant={"rect"} width={168} />
              <Skeleton variant={"rect"} width={100} />
            </span>
            <span className={classes.mobileDate}>
              <Skeleton variant={"rect"} width={71} />
            </span>
          </div>
          <Skeleton variant={"rect"} className={classes.mobileImage} height={"100%"} width={"40%"}/>
        </div>
      </Hidden>
    </>
  );
}


export default ArticleCardSkeleton;
