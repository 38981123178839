import React, { useEffect, useState, useRef, useCallback } from "react";
import { buildImageObj, publishDate, createClient } from "../../lib/helpers";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../../components/container";
import { Grid, Hidden, Typography } from "@material-ui/core";
import * as styles from "./allArticles.module.css";
import ArticleCard from "./articleCard";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import SearchImage from "./img/search.svg";
import queryString from "query-string";
import { useLocation } from "@reach/router";
import ArticleCardSkeleton from "./articleCardSkeleton"

const client = createClient();
const pageType = "post";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      background: 'linear-gradient(90deg, #505285 0%, #585E92 50%, #65689F 100%);',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& .MuiPaginationItem-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial !important'
      }
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    }
  },

  cardDiv: {

  },
  searchImage: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    height: '32px',
    width: '32px',
    cursor: 'pointer'
  },
  upperSearchContainer: {
    padding: '16px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottom: '1px solid #E6E8F0'
  },
  searchTitle: {
    color: '#262641',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    fontFamily: 'Poppins'
  },
  searchContainer: {
    boxShadow: 'inset 0px 1px 0px #E6E8F0',
    background: '#FFFFFF',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    border: '1px solid #DBE3EF'
  },
  bottomSearchContainer: {
    padding: '16px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  searchBarContainer: {
    position: 'relative'
  }
}));


export default function AllArticles(props) {

  const { pageCount, slugToSearch } = props;
  const [startrec, setStartRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(pageCount[0].pageCount);
  const [page, setPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchString, setSearchString] = useState('');

  const [loading, setLoading] = useState(true)
  const placeholderAmount = [0, 1, 2, 3, 4];
  const searchExcludedWords = ['in', 'at', 'a', 'on', 'it', 'the', 'is', 'for', 'to', 'and'];

  const [allArticles, setAllArticles] = useState([]);

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  const searchButton = useRef(null);
  const location = useLocation();


  const onRefChange = useCallback((node) => {
    if (node === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === "{}") {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            article_search_phrase: JSON.stringify(googleAnalyticsObject),
          });

          window.dataLayer.push({ event: "article_search" });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  useEffect(() => {
    (async () => {
      await getArticles();

      var searchString = queryString.parse(location.search).search;

      if (searchString && searchString.length > 0) {
        setSearchPhrase(decodeURIComponent(searchString).replace(/[^a-zA-Z0-9 ]/g, ''));
        setSearchString(decodeURIComponent(searchString).replace(/[^a-zA-Z0-9 ]/g, ''));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (searchString && searchString.length > 0) {
        await search(1);

        setGoogleAnalyticsObject({
          searchPhrase: searchString
        });
      }
    })();

  }, [searchString]);

  var getArts = async function () {

    var searchTerm = "*";
    var searchCat = "*";

    if (slugToSearch != "all") {
      searchTerm = slugToSearch.replace("-", "")

      searchCat = props.pageCats[0]._id;
    }

    if (slugToSearch == "hollys-blog") {
      searchCat = props.pageCats[0]._id;
    }

    const today = new Date().toISOString();

    const params = {
      pageType: pageType,
      artCount: Number(itemsPerPage) - 1,
      startRecord: Number(startrec),
      category: searchCat,
      today: today,
    }

    var query = '*[_type == $pageType && defined(publishedAt) && defined(title) && publishedAt <=$today && defined(slug)]{title, authors,authorsText, _updatedAt, publishedAt, slug, mainImage, categories, _rawBody, body, "bodyTexts": body[].children[], "catTitle":categories[] ->{title}, "cats":categories[]._ref,"people2": authors[].people->{_id, name}}[cats match [$category]] | order(publishedAt desc)'

    return client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  var getArticles = async function () {
    var arts = await getArts();

    var filteredArticles = arts;

    if (searchPhrase !== '') {
      filteredArticles = arts.filter(searchPhraseFilterFunction);
      setArticles(filteredArticles.slice(0, itemsPerPage));
      setTotalPages(Math.ceil(filteredArticles.length / itemsPerPage));
      setLoading(false);
    } else {
      setArticles(arts.slice(0, itemsPerPage));
      setTotalPages(Math.ceil(arts.length / itemsPerPage));
    }
    setAllArticles(arts);

  }

  //var getArtsPg = async function (startrec,endrec) {

  //  const params = {
  //    pageType: pageType,
  //    artCount: Number(endrec)-1,
  //    startRecord: Number(startrec),
  //    category: slugToSearch
  //  }

  //  var query = '*[_type == $pageType]{title, authors, _updatedAt, slug, mainImage, categories} | order(_updatedAt desc)  [$startRecord..$artCount]'

  //  return client.fetch(query, params)
  //    .then((response) => response)
  //    .then((responseJson) => { return responseJson });
  //}

  const handlePageChange = async (event, value) => {

    setPage(value);
    window.scrollTo(0, 0)
    await search(value);
  };

  var search = async function (newPageNumber) {

    var lastPage = (newPageNumber - 1);

    var startrecord = 0;

    if (lastPage != 0) {
      startrecord = ((newPageNumber - 1) * parseInt(itemsPerPage));
    }

    var endrecord = (startrecord + parseInt(itemsPerPage));

    //var arts = await getArtsPg(startrecord, endrecord);
    var arts = allArticles;
    var filteredArticles = [];



    if (allArticles.length == 0) {
      arts = await getArts();

      filteredArticles = arts;

      if (searchPhrase !== '') {
        filteredArticles = arts.filter(searchPhraseFilterFunction);

        //setAllArticles(filteredArticles);

        setTotalPages(Math.ceil(filteredArticles.length / itemsPerPage));

        setLoading(false);
      }
      else {
        setTotalPages(Math.ceil(arts.length / itemsPerPage));
      }
      setAllArticles(arts);
    }

    if (searchPhrase !== '') {
      filteredArticles = arts.filter(searchPhraseFilterFunction);
      setTotalPages(Math.ceil(filteredArticles.length / itemsPerPage));

      setArticles(filteredArticles.slice(startrecord, endrecord));
    } else {
      setArticles(arts.slice(startrecord, endrecord));
      setTotalPages(Math.ceil(arts.length / itemsPerPage));
    }
  }

  const classes = useStyles();

  var handleKeyDown = async function (e) {
    if (e.key === 'Enter') {
      await search(1);

      setGoogleAnalyticsObject({
        searchPhrase: searchPhrase
      });
    }
  }

  var searchPhraseFilterFunction = function (article) {
    var bodyTextMatch = checkBodyTextMatchesSearchPhrase(article.bodyTexts);
    var titleMatch = checkTextToSearchIncludesSearchPhrase(article.title.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ''));

    return article.title && (titleMatch || bodyTextMatch);
  }

  var checkBodyTextMatchesSearchPhrase = function (bodyTextsArray) {
    if (!bodyTextsArray || bodyTextsArray.length === 0) {
      return false;
    }

    for (var i = 0; i < bodyTextsArray.length; i++) {
      if (!bodyTextsArray[i] || bodyTextsArray[i].length === 0) {
        continue;
      }

      for (var j = 0; j < bodyTextsArray[i].length; j++) {
        if (!bodyTextsArray[i][j] || !bodyTextsArray[i][j].text) {
          continue;
        }

        var textToSearchIn = bodyTextsArray[i][j].text.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');

        if (checkTextToSearchIncludesSearchPhrase(textToSearchIn)) {
          return true;
        }
      }
    }

    return false;
  }

  var checkTextToSearchIncludesSearchPhrase = function (textToSearchIn) {
    var searchPhaseSplit = searchPhrase.split(' ');

    if (searchPhaseSplit && searchPhaseSplit.length > 0) {
      for (var k = 0; k < searchPhaseSplit.length; k++) {
        var searchWord = searchPhaseSplit[k].toLowerCase();

        var isExcludedWord = checkIsSearchExcludedWord(searchWord);

        if (isExcludedWord) {
          continue;
        }

        if (textToSearchIn.includes(searchWord)) {
          return true;
        }
      }
    }

    return false;
  }

  var checkIsSearchExcludedWord = function (word) {
    for (var i = 0; i < searchExcludedWords.length; i++) {
      if (word === searchExcludedWords[i]) {
        return true;
      }
    }

    return false;
  }

  return (
    <Grid>
      <Grid container className={classes.searchContainer}>
        <Grid item xs={12} className={classes.upperSearchContainer}>
          <Grid container>
            <Grid item xs={12}>
              <span className={classes.searchTitle}>Search our articles</span>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.nameLabel}>
                If you are looking for something specific, use our search bar to browse our library of articles and content.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.bottomSearchContainer}>
          <Grid container>
            <Grid item xs={12} className={classes.searchBarContainer}>
              <input placeholder="Search articles" type="text" defaultValue={searchString} onChange={event => setSearchPhrase((event.target.value).replace(/[^a-zA-Z0-9 ]/g, ''))} className={styles.searchBar} onKeyDown={handleKeyDown} />
              <img src={SearchImage} className={classes.searchImage} onClick={() => search(1)} ref={searchButton} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={styles.filteredQuestionsContainer} >
        {loading && placeholderAmount.map(() => <ArticleCardSkeleton />)}
        {articles && Array.isArray(articles) && (


          articles.map((ar, index) => {

            return <div ref={index === 0 ? onRefChange : null}><ArticleCard article={ar} searchTerm={slugToSearch} /> </div>

          })
        )}

      </div>
      <Grid container alignItems="center" justify="center" className={styles.paginationContainer}>
        <Grid item>
          {articles.length > 0 &&
            <Pagination
              count={totalPages}
              page={page}
              className={classes.root}
              onChange={handlePageChange}
              rowsPerPage={5}
            />}
        </Grid>
      </Grid>
    </Grid>
  )

}


